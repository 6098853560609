import { z } from 'zod'

export enum AiAutomationContextType {
  TEXT = 'text',
  FILE = 'file',
  LINK = 'link',
}

const businessContextChunkSchema = z.object({
  type: z.nativeEnum(AiAutomationContextType),
  content: z.string(),
})

const aiAutomationConfigSchema = z.object({
  business_context: z.array(businessContextChunkSchema),
  scenarios: z.object({
    faq: z.object({
      published: z.boolean(),
    }),
  }),
})

export const AiAutomationSchemas = {
  getConfig: {
    response: z.object({
      config: aiAutomationConfigSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
  updateConfig: {
    request: z.object({
      config: aiAutomationConfigSchema,
    }),
    response: z.object({
      config: aiAutomationConfigSchema,
    }),
    query: z.undefined(),
    path: z.undefined(),
  },
}

export type AiAutomationConfig = z.infer<typeof aiAutomationConfigSchema>
export type BusinessContextChunk = z.infer<typeof businessContextChunkSchema>
