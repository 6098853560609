import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'reduxTyped'

import { CmsAnalytics } from 'apps/cms/lib/analytics'
import { getBuilderUrl } from 'apps/cms/lib/getBuilderUrl'
import { contentManagementActions, contentManagementSelectors } from 'apps/cms/store'
import { openModal } from 'apps/quickCampaigns/selectTemplateModal/store/selectTemplateModalSlice'
import { FSSmartFolders } from 'common/cms/models/FSObject/constants'
import { openAutomationStartersModal } from 'common/core/actions/uiActions'
import {
  hasConnectedInstagramChannel,
  hasConnectedTiktokChannel,
  isFBChannelConnected,
} from 'common/core/selectors/channelSelectors'
import * as settingsSelectors from 'common/settings/selectors/settingsSelectors'
import { UserFlag } from 'utils/services/featureFlagsService/constants'
import { useUserReleaseToggle } from 'utils/services/featureFlagsService/releaseTogglesHooks'
import { useHotjar } from 'utils/services/hotjar/useHotjar'

export const useNewAutomationButton = () => {
  const dispatch = useAppDispatch()

  const isAvailableAutomationStarters = useUserReleaseToggle(UserFlag.ENABLE_NEW_QUICK_CAMPAIGNS)

  const { hotjarTrigger } = useHotjar()
  const navigate = useNavigate()
  const isSearchOrFilterApplied = useAppSelector(
    contentManagementSelectors.getIsSearchOrFilterApplied,
  )
  const { disableFlowCreation } = useAppSelector(contentManagementSelectors.getUi)

  const { path } = useAppSelector(contentManagementSelectors.getQuery)

  const showTemplatesModal = useAppSelector(
    (state) =>
      settingsSelectors.isFlowTemplatesEnabled(state) &&
      (isFBChannelConnected(state) ||
        hasConnectedInstagramChannel(state) ||
        hasConnectedTiktokChannel(state)),
  )

  const createAutomation = useCallback(async () => {
    dispatch(contentManagementActions.setIsFlowCreationStarted(true))

    if (disableFlowCreation) {
      CmsAnalytics.logCreateAutomationModalSelectFlow()
      hotjarTrigger('new_flow_from_select_flow_clicked', ['new_flow_from_select_flow_clicked'])
      return
    }

    CmsAnalytics.logCreateAutomation()
    hotjarTrigger('new_flow_clicked', ['new_flow_clicked'])

    const flowPath =
      (Object.values(FSSmartFolders) as string[]).includes(path) || isSearchOrFilterApplied
        ? '/'
        : path

    if (showTemplatesModal) {
      if (isAvailableAutomationStarters) {
        dispatch(openAutomationStartersModal())
      } else {
        const folderId = Number(flowPath.split('/').pop()) ?? null

        dispatch(
          openModal({
            path: flowPath,
            folderId,
            ns: null,
          }),
        )
      }
    } else {
      const data = await dispatch(
        contentManagementActions.createAutomation({ path: flowPath }),
      ).unwrap()
      if (!data) return

      navigate(getBuilderUrl(data.fs_object.path))
    }
  }, [
    dispatch,
    disableFlowCreation,
    hotjarTrigger,
    path,
    isSearchOrFilterApplied,
    navigate,
    showTemplatesModal,
    isAvailableAutomationStarters,
  ])

  return {
    createAutomation,
  }
}
