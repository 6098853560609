import dot from 'dot-prop-immutable'
import findIndex from 'lodash/findIndex'
import { AnyAction } from 'redux'

import { isMobile } from 'utils'
import { ChannelType } from 'common/core/constants/ChannelType'
import * as atypes from 'common/core/constants/UIReduxActionTypes'
import { UI } from 'common/core/interfaces/ui'
import localStorage from 'utils/localStorage'

const getInitialSidebarOpenedState = () => {
  let isOpened = true

  // on mobile devices we want to have sidebar closed by default
  if (isMobile) {
    isOpened = false
    return isOpened
  }

  // if user visited the app before and closed the sidebar, we want to keep it closed
  // but if user never visited the app before, we want to have sidebar opened by default
  // thats why we check if localStorage has the key
  if (localStorage.getItem('mcht_sidebar_hide_state')) {
    isOpened = localStorage.getItem('mcht_sidebar_hide_state') === 'false'
    return isOpened
  }

  return isOpened
}

export const InitialUiState: UI = {
  allowedSettingsTabs: [],
  channels: [ChannelType.FB, ChannelType.SMS, ChannelType.EMAIL],
  connectChannelModalSource: null,
  isCompactSettingsModalOpened: false,
  isHeaderEditing: false,
  errorHeaderEditingTimestamp: null,
  isConnectChannelModalOpened: false,
  isSidebarFolded: Boolean(localStorage.getItem('mcht_sidebar_folded')),
  isSidebarOpened: getInitialSidebarOpenedState(),
  isSwitchBotDialogOpened: false,
  isFlowBuilderHelpModalOpened: false,

  IGChangeAccountError: {
    isShowIgChangeAccountErrorModal: false,
    isIgChangeAccountError: false,
  },
  lightbox: {
    index: 0,
    items: [],
  },
  notifications: [],
  previewModal: {
    isOpen: false,
    keyword: '',
  },
  firstAutomationCreationModal: {
    isModalOpen: false,
  },
  waCoexistenceContactImportModal: {
    isOpen: false,
  },
  firstTiktokAutomationCreation: {
    isFirstSession: false,
    isModalOpen: false,
    showFeedback: false,
    isPopupOpen: false,
  },
  settingsTab: null,
  statusOfCGTNotAllowedModal: 'close',
  quickCampaignPreviewModal: { isOpen: false, quickCampaignId: null },
  automationStartersModal: {
    isOpen: false,
  },
}

export default function uiReducer(state = InitialUiState, action: AnyAction) {
  switch (action.type) {
    case atypes.UI_OPEN_CONNECT_CHANNEL_MODAL: {
      return {
        ...state,
        isConnectChannelModalOpened: true,
        channels: action.channels,
        connectChannelModalSource: action.source,
      }
    }
    case atypes.UI_CLOSE_CONNECT_CHANNEL_MODAL: {
      return { ...state, isConnectChannelModalOpened: false }
    }
    case atypes.UI_SHOW_IG_CHANGE_ACCOUNT_ERROR_MODAL: {
      return {
        ...state,
        IGChangeAccountError: {
          ...state.IGChangeAccountError,
          isShowIgChangeAccountErrorModal: true,
        },
      }
    }
    case atypes.UI_CLOSE_IG_CHANGE_ACCOUNT_ERROR_MODAL: {
      return {
        ...state,
        IGChangeAccountError: {
          ...state.IGChangeAccountError,
          isShowIgChangeAccountErrorModal: false,
        },
      }
    }
    case atypes.UI_IG_CHANGE_ACCOUNT_ERROR: {
      const { isIgChangeAccountError } = action
      return {
        ...state,
        IGChangeAccountError: {
          ...state.IGChangeAccountError,
          isIgChangeAccountError: isIgChangeAccountError,
        },
      }
    }
    case atypes.UI_OPEN_COMPACT_SETTINGS_MODAL: {
      const { settingsTab } = action
      return {
        ...state,
        isCompactSettingsModalOpened: true,
        settingsTab,
        allowedSettingsTabs: action.allowedSettingsTabs || [],
      }
    }
    case atypes.UI_CLOSE_COMPACT_SETTINGS_MODAL: {
      const { settingsTab } = action
      return { ...state, isCompactSettingsModalOpened: false, settingsTab, allowedSettingsTabs: [] }
    }

    // Preview Modal
    case atypes.UI_OPEN_PREVIEW_MODAL: {
      return {
        ...state,
        previewModal: {
          isOpen: true,
          keyword: action.keyword,
        },
      }
    }
    case atypes.UI_CLOSE_PREVIEW_MODAL: {
      return { ...state, previewModal: { isOpen: false } }
    }

    case atypes.UI_OPEN_FIRST_AUTOMATION_CREATION_MODAL: {
      return {
        ...state,
        firstAutomationCreationModal: {
          isModalOpen: true,
        },
      }
    }

    case atypes.UI_CLOSE_FIRST_AUTOMATION_CREATION_MODAL: {
      return { ...state, firstAutomationCreationModal: { isModalOpen: false } }
    }

    case atypes.UI_OPEN_WA_COEXISTENCE_CONTACT_IMPORT_MODAL: {
      return dot.set(state, 'waCoexistenceContactImportModal.isOpen', true)
    }

    case atypes.UI_CLOSE_WA_COEXISTENCE_CONTACT_IMPORT_MODAL: {
      return dot.set(state, 'waCoexistenceContactImportModal.isOpen', false)
    }

    case atypes.UI_OPEN_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL: {
      return dot.set(state, 'firstTiktokAutomationCreation.isModalOpen', true)
    }

    case atypes.UI_CLOSE_FIRST_TIKTOK_AUTOMATION_CREATION_MODAL: {
      return dot.set(state, 'firstTiktokAutomationCreation.isModalOpen', false)
    }

    case atypes.UI_SET_FIRST_TIKTOK_AUTOMATION_SESSION: {
      return dot.set(state, 'firstTiktokAutomationCreation.isFirstSession', true)
    }

    case atypes.UI_UNSET_FIRST_TIKTOK_AUTOMATION_SESSION: {
      return dot.set(state, 'firstTiktokAutomationCreation.isFirstSession', false)
    }

    case atypes.UI_SET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK: {
      return dot.set(state, 'firstTiktokAutomationCreation.showFeedback', true)
    }

    case atypes.UI_UNSET_FIRST_TIKTOK_AUTOMATION_SHOW_FEEDBACK: {
      return dot.set(state, 'firstTiktokAutomationCreation.showFeedback', false)
    }

    case atypes.UI_OPEN_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP: {
      return dot.set(state, 'firstTiktokAutomationCreation.isPopupOpen', true)
    }

    case atypes.UI_CLOSE_FIRST_TIKTOK_AUTOMATION_FLOW_POPUP: {
      return dot.set(state, 'firstTiktokAutomationCreation.isPopupOpen', false)
    }

    case atypes.UI_RESET_FIRST_TIKTOK_AUTOMATION: {
      return dot.set(
        state,
        'firstTiktokAutomationCreation',
        InitialUiState.firstTiktokAutomationCreation,
      )
    }

    case atypes.UI_OPEN_LIGHTBOX: {
      return { ...state, lightbox: { index: 0, items: action.items } }
    }
    case atypes.UI_CLOSE_LIGHTBOX: {
      return { ...state, lightbox: InitialUiState.lightbox }
    }

    case atypes.UI_OPEN_SIDEBAR: {
      return { ...state, isSidebarOpened: true }
    }

    case atypes.UI_SET_CGT_NOT_ALLOWED_MODAL_STATUS: {
      return { ...state, statusOfCGTNotAllowedModal: action.statusOfCGTNotAllowedModal || 'close' }
    }

    case atypes.UI_TOGGLE_SIDEBAR_FOLDING: {
      return { ...state, isSidebarFolded: !state.isSidebarFolded }
    }

    case atypes.UI_CLOSE_SIDEBAR: {
      return { ...state, isSidebarOpened: false }
    }

    case atypes.UI_ADD_NOTIFICATION: {
      const { item } = action
      return {
        ...state,
        notifications: [...state.notifications, item],
      }
    }

    case atypes.UI_DELETE_NOTIFICATION: {
      const { id } = action
      const index = findIndex(state.notifications, { id })
      if (index < 0) {
        return state
      }
      return dot.delete(state, `notifications.${index}`)
    }

    case atypes.UI_NOTIFY_ADMIN_MIGRATION_SHOW_MODAL: {
      return { ...state, notifyAdminMigrationModalOpen: true }
    }

    case atypes.UI_NOTIFY_ADMIN_MIGRATION_HIDE_MODAL: {
      return { ...state, notifyAdminMigrationModalOpen: false }
    }
    case atypes.UI_SET_HEADER_EDITING: {
      return { ...state, isHeaderEditing: action.payload }
    }
    case atypes.UI_SET_ERROR_HEADER_EDITING_TS: {
      return { ...state, errorHeaderEditingTimestamp: action.payload }
    }
    case atypes.UI_OPEN_FLOWBUILDER_HELP_MODAL: {
      return { ...state, isFlowBuilderHelpModalOpened: true }
    }
    case atypes.UI_CLOSE_FLOWBUILDER_HELP_MODAL: {
      return { ...state, isFlowBuilderHelpModalOpened: false }
    }

    case atypes.UI_OPEN_QUICK_CAMPAIGN_PREVIEW_MODAL: {
      return {
        ...state,
        quickCampaignPreviewModal: { isOpen: true, quickCampaignId: action.quickCampaignId },
      }
    }
    case atypes.UI_CLOSE_QUICK_CAMPAIGN_PREVIEW_MODAL: {
      return {
        ...state,
        quickCampaignPreviewModal: { isOpen: false, quickCampaignId: null },
      }
    }
    case atypes.UI_OPEN_AUTOMATION_STARTERS_MODAL: {
      return { ...state, automationStartersModal: { isOpen: true } }
    }
    case atypes.UI_CLOSE_AUTOMATION_STARTERS_MODAL: {
      return { ...state, automationStartersModal: { isOpen: false } }
    }
  }

  return state
}
