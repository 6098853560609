import { api } from 'shared/api'
import { AiAutomationSchemas } from 'shared/api/requests/aiAutomation/schemas'

export const AiAutomationApi = {
  getConfig: api.account.createGet({
    url: '/aiAutomation/getConfig',
    schemas: AiAutomationSchemas.getConfig,
  }),
  updateConfig: api.account.createPost({
    url: '/aiAutomation/updateConfig',
    schemas: AiAutomationSchemas.updateConfig,
  }),
}
