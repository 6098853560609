import { createSelector } from '@reduxjs/toolkit'
import find from 'lodash/find'

import { localStorage } from 'utils'
import { UI } from 'common/core/interfaces/ui'

export const getUI = (state: RootState): UI => state.ui

export const getNotificationById = (state: RootState, id: string) => {
  return find(state.ui.notifications, { id })
}

export const getPreviewModal = createSelector(getUI, (ui) => ui.previewModal)

export const getConnectChannelModalSource = createSelector(
  getUI,
  (ui) => ui.connectChannelModalSource,
)

export const getIsSidebarHidden = (state: RootState) => {
  const savedValue = localStorage.getItem('mcht_sidebar_hide_state')
  return savedValue === 'true' || !state.ui.isSidebarOpened
}

export const getIsFirstAutomationCreationModalOpen = (state: RootState) => {
  return state.ui.firstAutomationCreationModal.isModalOpen
}

export const getFirstTiktokAutomationCreation = (state: RootState) => {
  return state.ui.firstTiktokAutomationCreation
}

export const getIsWaCoexistenceContactImportModalOpen = (state: RootState) => {
  return state.ui.waCoexistenceContactImportModal.isOpen
}

export const getIsShowIgChangeAccountErrorModal = createSelector(
  getUI,
  (ui) => ui.IGChangeAccountError.isShowIgChangeAccountErrorModal,
)

export const getIsIgChangeAccountError = createSelector(
  getUI,
  (ui) => ui.IGChangeAccountError.isIgChangeAccountError,
)

export const getQuickCampaignPreviewModal = createSelector(
  getUI,
  (ui) => ui.quickCampaignPreviewModal,
)

export const getIsAutomationStartersModalOpen = createSelector(
  getUI,
  (ui) => ui.automationStartersModal.isOpen,
)
