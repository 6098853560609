import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import { DeepPartial } from 'utility-types'

import { KnowledgeChunk } from 'apps/aiAlwaysOnAutomations/constants/types'
import { alert } from 'common/core'
import { AiAutomationApi } from 'shared/api/requests/aiAutomation'
import { AiAutomationConfig, BusinessContextChunk } from 'shared/api/requests/aiAutomation/schemas'
import { createAsyncAction } from 'shared/lib/redux'

const NAMESPACE = 'aiAlwaysOnAutomations'

export enum LoaderStatus {
  NOT_LOADED = 'NOT_LOADED',
  LOADED = 'LOADED',
  DASHBOARD_LOADING = 'DASHBOARD_LOADING',
  FAQ_LIVE_LOADING = 'FAQ_LIVE_LOADING',
  KNOWLEDGE_EDITOR_LOADING = 'KNOWLEDGE_EDITOR_LOADING',
}

export interface AiAlwaysOnAutomationsState {
  config: AiAutomationConfig
  selectedKnowledgeChunkIndex: number | null
  loaderStatus: LoaderStatus
}

export const AiAlwaysOnAutomationsInitialState: AiAlwaysOnAutomationsState = {
  config: {
    business_context: [],
    scenarios: {
      faq: {
        published: false,
      },
    },
  },
  selectedKnowledgeChunkIndex: null,
  loaderStatus: LoaderStatus.NOT_LOADED,
}

const { reducer, actions } = createSlice({
  initialState: AiAlwaysOnAutomationsInitialState,
  name: NAMESPACE,
  reducers: {
    setData: (state, action: PayloadAction<AiAutomationConfig>) => {
      state.config = action.payload
    },
    setSelectedKnowledgeChunkIndex: (state, action: PayloadAction<number | null>) => {
      state.selectedKnowledgeChunkIndex = action.payload
    },
    setFaqScenarioStatus: (state, action: PayloadAction<boolean>) => {
      state.config.scenarios.faq.published = action.payload
    },
    setLoaderStatus: (state, action: PayloadAction<LoaderStatus>) => {
      state.loaderStatus = action.payload
    },
  },
})

export const AiAlwaysOnAutomationsReducer = reducer

const getState = (state: RootState) => state.aiAlwaysOnAutomations

export const AiAlwaysOnAutomationsSelectors = {
  getState,
  getBusinessContext: (state: RootState) => getState(state).config.business_context,
  getSelectedKnowledgeChunkIndex: (state: RootState) => getState(state).selectedKnowledgeChunkIndex,
  getSelectedKnowledgeChunk: (state: RootState) => {
    const index = getState(state).selectedKnowledgeChunkIndex
    if (index === null) {
      return undefined
    }
    return getState(state).config.business_context[index]
  },
  getFaqScenarioStatus: (state: RootState) => getState(state).config.scenarios.faq.published,
  getConfig: (state: RootState) => getState(state).config,
  getLoaderStatus: (state: RootState) => getState(state).loaderStatus,
}

const getConfig = createAsyncAction(`${NAMESPACE}/getConfig`, async (_, { dispatch }) => {
  dispatch(actions.setLoaderStatus(LoaderStatus.DASHBOARD_LOADING))

  const { data } = await AiAutomationApi.getConfig()
  dispatch(actions.setData(data.config))
  dispatch(actions.setLoaderStatus(LoaderStatus.LOADED))
})

const updateConfig = createAsyncAction(
  `${NAMESPACE}/updateConfig`,
  async (changes: DeepPartial<AiAutomationConfig>, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())
    const newScenarios = merge({}, config.scenarios, changes.scenarios)
    const newConfig: AiAutomationConfig = {
      scenarios: newScenarios,
      business_context: (changes.business_context ||
        config.business_context) as BusinessContextChunk[],
    }

    await AiAutomationApi.updateConfig({
      body: { config: newConfig },
    })

    dispatch(actions.setData(newConfig))
    dispatch(actions.setLoaderStatus(LoaderStatus.LOADED))
  },
)

const removeChunk = createAsyncAction(
  `${NAMESPACE}/removeChunk`,
  async (removedChunkIndex: number, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())
    const updatedContext = config.business_context.filter((_, index) => index !== removedChunkIndex)

    const newConfig = {
      ...config,
      business_context: updatedContext,
    }

    await dispatch(updateConfig(newConfig))
  },
)

const editKnowledgeChunk = createAsyncAction(
  `${NAMESPACE}/editKnowledgeChunk`,
  async (data: KnowledgeChunk, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())
    const index = AiAlwaysOnAutomationsSelectors.getSelectedKnowledgeChunkIndex(getState())

    if (index !== null) {
      const newBusinessContext = [...config.business_context]
      newBusinessContext[index] = data

      dispatch(actions.setLoaderStatus(LoaderStatus.KNOWLEDGE_EDITOR_LOADING))
      await dispatch(updateConfig({ business_context: newBusinessContext }))
      dispatch(actions.setSelectedKnowledgeChunkIndex(null))
      alert('Chunk was been edited', 'success')
    }
  },
)

const addKnowledgeChunk = createAsyncAction(
  `${NAMESPACE}/addKnowledgeChunk`,
  async (data: KnowledgeChunk, { dispatch, getState }) => {
    const config = AiAlwaysOnAutomationsSelectors.getConfig(getState())

    dispatch(actions.setLoaderStatus(LoaderStatus.KNOWLEDGE_EDITOR_LOADING))
    await dispatch(updateConfig({ business_context: [...config.business_context, data] }))
    alert('Chunk was been added', 'success')
  },
)

const setFaqScenarioStatus = createAsyncAction(
  `${NAMESPACE}/setFaqScenarioStatus`,
  async (status: boolean, { dispatch }) => {
    dispatch(actions.setLoaderStatus(LoaderStatus.FAQ_LIVE_LOADING))
    await dispatch(
      updateConfig({
        scenarios: {
          faq: {
            published: status,
          },
        },
      }),
    )
  },
)

export const AiAlwaysOnAutomationsActions = {
  removeChunk,
  setSelectedKnowledgeChunkIndex: actions.setSelectedKnowledgeChunkIndex,
  editKnowledgeChunk,
  addKnowledgeChunk,
  setFaqScenarioStatus,
  getConfig,
}
